@import '../../../assets/global-style/mixin';

.ns-wrapper {
    width: 100%;
    max-width: 1341px;
    padding: 0 50px;
    margin: 0 auto;
    box-sizing: border-box;

    @include before_768 {
        padding: 0 20px;
    }

	@include before_550 {
        padding: 0 10px;
    }
}
