@import '../../../assets/global-style/mixin';


.ns-header {
    padding-bottom: 20px;

	.ns-cell-select__item  {
		padding: 0;
		a {
			padding: 16px 24px;
		}
	}

	&__stiker {
		height: 100%;
		margin: auto 0;
	    max-width: 70px;
		padding: 3px 5px;
		border-radius: 8px;
		min-width: 62px;
	}

    &__flex-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__content {
        &--top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            padding: 14px 0 8px 0;

            width: 100%;
        }

        &--bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            background: var(--s-secondary-c-3);
            border-radius: 24px;
            padding: 12px 24px;

            width: 100%;

            @include before_1024 {
                display: none;
            }
        }
    }

    &__img-box {
        cursor: pointer;
        flex-shrink: 0;
		display: flex;
    }

    &__el {
		position: relative;
        display: flex;
        align-items: center;
        gap: 26px;

        &--left {
            @include before_1024 {
                // justify-content: space-between;
                width: 100%;
            }
        }

        &--right {
			gap: 15px;
            @include before_480 {
                gap: 20px;
            }
        }
    }

    &__menu {
        display: flex;
        gap: 57px;


		@include before_1200 {
            gap: 20px;
        }

        @include before_550 {
            display: none;
        }
    }

    &__menu-list {
        display: flex;
        gap: 32px;

		@include before_1100 {
			display: none;
		}
    }

    &__menu-item {
        height: 20px;
        position: relative;
		display: flex;
    }

    &__nav-list {
        display: flex;
        gap: 24px;

        margin-bottom: 0;
    }

    &__nav-item {
        position: relative;
        cursor: pointer;

        p {
            margin: 0;
        }
    }

    .ns-header__nav-text {
        transition: color 0.3s;
		margin: 10px auto;
        &:hover {
            color: var(--t-accent-c);
        }
    }

    &__nav-item:hover &__popup-list {
        visibility: visible;
        opacity: 1;
    }


    &__popup-list {
        position: absolute;
        top: 45px;
        left: -17px;
        background: var(--s-inverted-c);
        box-shadow: 0px 2px 15px #ced1d5;
        border-radius: 16px;
        z-index: 20;
        max-width: 324px;
        max-height: 900px;

        width: max-content;
        visibility: hidden;
        opacity: 0;
        transition: 0.5s;

        &.scroll {
            overflow-y: scroll;
        }

		&.open {
			visibility: visible;
			opacity: 1;
        }
    }

    &__popup-item {
        padding: 16px 24px;
    }
	&__popup-sticker {
        display: flex;
		flex-direction: row;
		justify-content: space-between;

    }
	&__dropdown-sticker {
        display: flex;
		flex-direction: row;
        justify-content: space-between;
		gap: 25px;

        @include before_1100 {
			align-items: center;
		}

		@include before_550 {
			gap: 8px;
		}
    }

    &__popup-item:not(:last-child) {
		border-bottom: 0.2px solid rgba(231, 232, 239, 0.66);
    }

    &__link {
		align-items: end;
        color: var(--t-primery-c);
        text-decoration: none;
        border-bottom: none;
        transition: none;
		display: inline-flex;
    }

    &__link:hover {
        color: var(--t-primery-c);
        text-decoration: none;
        border-bottom: none;
        transition: none;
    }

    &__popup-link {
        text-decoration: none;
        border-bottom: none;
        transition: none;
        transition: 0.5s;
    }

    &__popup-link:hover {
        color: var( --t-accent-c);
        text-decoration: none;
    }

    &__active-menu {
        position: absolute;
        bottom: -19px;
        background: var( --s-accent-c);
        border-radius: 15px 15px 0px 0px;
        height: 3px;
        width: 100%;
    }

    &__search-icon {
        @include before_768 {
            display: none !important;
        }
    }

    &__helper-icon {
        display: none;

        @include before_1024 {
            display: block;
        }
    }

    &__mob-menu {
        display: none;
        position: relative;
        width: 26px;
        height: 26px;
        // margin-left: 34px;

        @include before_1100 {
            display: block;
        }
    }

    &__logo {
        max-width: 171px;
        width: 100%;
        height: 16px;
        display: block;
        object-fit: contain;
        object-position: center;
    }

    ul {
        margin-bottom: 0;
    }
}
.ns-dropdown {
	&__inner-list {
		@include before_550 {
			padding: 12px 0 24px 50px;
		}
	}
}
