@import '../../components/other/NStiker/sticker';
@import '../../components/structure/NHeader/header';
@import '../../components/structure/NGrid/grid';
@import '../../components/structure/NWrapper/wrapper';
@import '../../components/structure/NMain/main';
@import '../../components/other/NMenu/menu';
@import '../../components/structure/NFooter/footer';
@import '../../components/typography/NText/Text';
@import '../../components/buttons/NButton/button';
@import '../../components/buttons/NButtonText/buttonText';
@import '../../components/typography/NTitle/Title';

.language-btn {
	position: relative;
	height: 41px;
	width: 41px;
	@include before_768 {
		display: none;
	}
}

.language-dropdown {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	text-align: center;
	display: inline-flex;
	justify-content: center;
	flex-direction: column;
	border-radius: 45%;
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 149.57%), linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48));
	box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.1);
	color: var(--s-secondary-c-2);
	@include before_668 {
		position: relative;
	}
	.js-language-dropdown {
		height: 41px;
		width: 41px;
		padding: 0;
		display: inline-flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		background: var(--s-inverted-c);
		border-radius: 50%;
		border: 1px solid #FFF;

	}
	&-content {
		height: 41px;
		width: 41px;
		display: none;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 149.57%), linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48));
		border-radius: 50%;
		border: 1px solid #FFF;
		z-index: 1;

		@include before_768 {
			right:-5px;
		}
		a {
			color: var(--s-accent-c);
			text-decoration: none;
			display: flex;
			justify-content: center;
			align-items: center;

		}
		&.active {
			display: inline-flex;
			justify-content: center;
			z-index: 9999999;
			top: 40px;
		}
	}
}


.b-footer__agreement_link2 {
	color: var(--t-descriptive-c-2);
	font-size: 12px;
	cursor: pointer;
}
.b-footer__agreement_link2 .b-link_dn {
	position: relative;
	display: none;
}
.b-footer__agreement_link2.active .b-link_dn {
	display: block;
	color: var(--t-descriptive-c-2);
	font-size: 12px;
}
.cc_banner-wrapper {
	z-index: 9001;
	position: relative;
}



.cc_banner .cc_message {
	width: 100%;
	max-width: 1050px;
	margin: 0 auto!important;
	line-height: 1.2!important;
	font-size: 12px!important;
	color: var(--t-inverted-c);
}
@media only screen and (max-width: 767px) {
	.cc_banner .cc_message {
		text-align: justify;
		margin-bottom: 60px!important;
	}
}
.cc_container {
	background: var(--s-inverted-c);
	color: var(--t-descriptive-c-2);
	font-size: 17px;
	box-sizing: border-box;
	border: 1px solid var(--s-secondary-c-4);
}
@media screen and (min-width: 768px) {
	.cc_container {
		padding: 15px 30px 15px;
	}
}

.cc_container {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	padding: 10px;
}
.cc_banner {
	background: var(--s-brand-c)!important;
}


.cc_container .cc_btn {
	cursor: pointer;
	text-align: center;
	font-size: 0.6em;
	transition: font-size 200ms;
	line-height: 1em;
}
@media only screen and (max-width: 767px) {
	.cc_container .cc_btn, .cc_container .cc_btn:visited {
		position: absolute;
		bottom: 0;
		left: calc(50% - 60px);
		width: 100%;
		height: 30px;
		padding: 0!important;
		float: none!important;
		margin: 10px auto!important;
		display: -ms-flexbox!important;
		display: flex!important;
		-ms-flex-align: center!important;
		align-items: center!important;
		-ms-flex-pack: center!important;
		justify-content: center!important;
		font-size: 1.2em!important;
		background-position: 100%;
	}
}


.cc_container, .cc_message, .cc_btn {
	animation-duration: 0.8s;
	-webkit-animation-duration: 0.8s;
	-moz-animation-duration: 0.8s;
	-o-animation-duration: 0.8s;
	-webkit-animation-name: slideUp;
	animation-name: slideUp;
}

.cc_container .cc_message {
	transition: font-size 200ms;
	font-size: 0.6em;
	display: block;
}

.cc_container .cc_message {
	font-size: 0.6em;
	transition: font-size 200ms;
	margin: 0;
	padding: 0;
	line-height: 1.5em;
}

.cc_container .cc_logo {
	display: none;
	text-indent: -1000px;
	overflow: hidden;
	width: 100px;
	height: 22px;
	background-size: cover;
	background-image: url(//cdnjs.cloudflare.com/ajax/libs/cookieconsent2/1.0.10/logo.png);
	opacity: 0.9;
	transition: opacity 200ms;
}


.cc_banner .cc_message a {
	color: var( --t-inverted-c)!important;
	border-bottom: 1px solid var( --t-inverted-c);
	transition: .5s;
}
.cc_banner .cc_message a:focus {
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}
.cc_banner .cc_message a:hover {
	border-bottom: 1px solid hsla(0,0%,100%,0);
}

.cc_container .cc_btn, .cc_container .cc_btn:visited {
	border-color: transparent!important;
	background-color: var(--s-inverted-c)!important;
	color:  var(--t-accent-c-2)!important;
	margin: 0 60px 0 10px;
	height: 35px;
	width: 35px;
}

.formulaZhizniEvent {
	padding: 88px 0 151px;
	&__block3 {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		grid-template-rows: repeat(1, 1fr);
		border-radius: 24px;
		border: 2px solid rgba(255, 255, 255, 0.80);
		background: var(--s-secondary-c-3);
		margin-top: 44px ;

		@include before_768 {
			grid-template-columns: repeat(1, 1fr);
			grid-template-rows: repeat(2, auto);
			padding: 38px 15px 9px;
		}

		& .formulaZhizniEvent__image-container {
			padding: 0;
			grid-column : 1 / 2;
			display: flex;
			justify-content: center;
			align-items: center;
			.b-text__img {
				margin: 0;
			}
			@include before_768 {
				grid-column : 1 / 2;
				grid-row : 2 / 3;
				padding: 0;
				justify-content: center;
			}

			& img {
				max-width: 281px;
				aspect-ratio: 1 / 0.8755;

				@include before_480 {
					width: 100%;
				}
			}
		}

		& .formulaZhizniEvent__info {
			grid-column : 2 / 7;
			padding: 25px;

			@include before_768 {
				grid-column : 1 / 2;
				grid-row : 1 / 2;
				padding: 0;
			}
		}
	}
}
