//Медиа запросы для разных ширин экрана
//Пример использования: @include desktop_1200 {font-size: 20px;}
@mixin before_1366 {
    @media screen and (max-width: 1365px) {
        @content;
    }
}

@mixin before_1220 {
    @media screen and (max-width: 1219px) {
        @content;
    }
}

@mixin before_1200 {
    @media screen and (max-width: 1200px) {
        @content;
    }
}

@mixin before_1100 {
    @media screen and (max-width: 1099px) {
        @content;
    }
}

@mixin before_1024 {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin after_1024 {
    @media screen and (min-width: 1023px) {
        @content;
    }
}

@mixin after_768 {
    @media screen and (min-width: 767px) {
        @content;
    }
}
@mixin before_968 {
    @media screen and (max-width: 968px) {
        @content;
    }
}

@mixin before_768 {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin before_668 {
    @media screen and (max-width: 668px) {
        @content;
    }
}
@mixin after_668 {
    @media screen and (min-width: 668px) {
        @content;
    }
}

@mixin before_550 {
    @media screen and (max-width: 549px) {
        @content;
    }
}
@mixin after_550 {
    @media screen and (min-width: 549px) {
        @content;
    }
}

@mixin before_480 {
    @media screen and (max-width: 479px) {
        @content;
    }
}
@mixin before_450 {
    @media screen and (max-width: 449px) {
        @content;
    }
}

@mixin before_380 {
    @media screen and (max-width: 380px) {
        @content;
    }
}
@mixin before_320 {
    @media screen and (max-width: 320px) {
        @content;
    }
}

//Добавление адаптивного шрифта
//Пример использования: @include adaptive-font(40, 24, 1280);
@mixin adaptive-font($pcSize, $mobSize, $maxWidth) {
    $addSize: $pcSize - $mobSize;
    $addMobSize: $addSize + $addSize * 0.7;

    @media (max-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addMobSize} * ((100vw - 320px) / #{$maxWidth}));
    }

    @media (min-width: 767px) {
        font-size: calc(#{$mobSize + px} + #{$addSize} * (100vw / #{$maxWidth}));
    }
}
