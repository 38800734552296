@import '../../../assets/global-style/mixin';


.ns-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 10px 30px;

	font-weight: 400;
	font-size: 18px;
	line-height: 25px;
	color: var(--t-inverted-c);
	text-decoration: none;
	text-align: center;

	background-color: var(--s-secondary-c-6);
	border: 1px solid var(--s-secondary-c-6);
	border-radius: 8px;

	box-sizing: border-box;
	cursor: pointer;
	outline: none;
	transition: 0.5s;
	-webkit-appearance: none; //убирает дефолтные стили в ios

	&__flex {
		display: flex;
		align-items: center;
	}

	&__el {
		&:not(:last-child) {
			margin-right: 8px;
		}
	}

	&__icon {
		fill: var(--t-inverted-c);
		transition: 0.5s;
	}

	&:hover {
		color: var(--t-descriptive-c-4);
		background-color: var(--s-inverted-c);

		.button1__icon {
			fill: var(--t-descriptive-c-4);
		}
	}

	&:active {
		opacity: 0.5;
		transition: 0.5s;
	}

	.button-svg {
		fill: var(--t-descriptive-c-5);
		color: var(--t-descriptive-c-5);
		transition: 0.5s;
	}
	&.active {
		background: var(--s-secondary-c-3);
		color: var(--t-primery-c);
		box-shadow: none;

		.button-svg {
			transform: rotate(180deg);
			fill: var(--t-primery-c);
			color: var(--t-primery-c);
		}
	}

	&:focus {
		opacity: 0.9;
		transition: 0.5s;
	}

	&.disabled {
		opacity: 0.6;
		pointer-events: none;
		background: grey;
	}

	&--primary {
		font-size: 16px;
		font-weight: 400;
		line-height: 21px;
		letter-spacing: 0em;
		text-align: center;

		background: var(--s-inverted-c);
		box-shadow: 6px 17px 24px rgba(73, 75, 89, 0.04);
		border-radius: 24px;
		padding: 11px 16px;
		border: none;
		color: var(--t-primery-c);
		&.stat {
			background: rgba(255, 255, 255, 0.29);
		}
		&__flex {
			align-items: flex-start;
		}

		&:hover {
			color: var(--t-primery-c);
			background-color: var(--s-inverted-c);

			.button__icon {
				fill: none;
			}
		}

		.button__icon {
			fill: none;
		}
	}

	&--full {
		width: 100% !important;
		cursor: pointer;
		height: 64px;
	}

	&--green {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;

		background: var(--t-accent-c-2);
		box-shadow: 0px 8px 16px rgba(36, 201, 33, 0.2);
		border-radius: 12px;
		color: var(--t-inverted-c);
		padding: 14px 16px;
		border: none;
		width: fit-content;
		z-index: 30;

		&__flex {
			align-items: center;
		}

		&:hover {
			color: var(--t-inverted-c);
			background: var(--s-brand-c);
		}

		&.disabled {
			pointer-events: none;
			background: grey;
		}

		@include before_668 {
			width: 100%;
		}

		&.w100 {
			width: 100%;
		}
	}

	&--white {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;

		background: var(--s-inverted-c);
		box-shadow: 0px 8px 16px 0px rgba(36, 201, 33, 0.2);
		border-radius: 12px;
		color: var(--s-accent-c);
		padding: 14px 16px;
		border: none;
		width: 220px;
		z-index: 30;

		&__flex {
			align-items: center;
		}

		&:hover {
			color: var(--t-accent-c-2);
			background: var(--s-inverted-c);
		}


		@include before_668 {
			width: 100%;
		}

		&.w100 {
			width: 100%;
		}
	}

	&--news {
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
		letter-spacing: 0px;
		text-align: center;
		color: var(--t-primery-c);

		background: transparent;
		border-radius: 6px;
		border: 1px solid rgba(53, 56, 80, 0.25);
		padding: 12px 32px;
		width: 100%;

		&__flex {
			align-items: center;
		}

		&:hover {
			color: var(--t-inverted-c);
			background: var(--s-secondary-c-1);
		}

		@include before_480 {
			width: 100%;
		}
	}

	&--circle {
		display: flex;
		align-items: center;
		justify-content: center;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 149.57%),
			linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48));
		border-radius: 50%;
		padding: 14px 16px;
		border: 1px solid;
		border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 149.57%);
		width: 41px;
		height: 41px;

		&__flex {
			align-items: flex-start;
		}
	}

	&--icon {
		display: block;
		border: none;
		padding: 0;
		background: transparent;
		bottom: 20px;
		right: 40px;
		position: absolute;

		&:hover {
			border: none;
			background: transparent;
		}
	}
}
.ns-button--hot-dekstop {
	@include before_668 {
		display: none;
	}
}
.ns-hot-line {
	&__buttons {
		display: flex;
		flex-direction: row;
		gap: 40px;
		margin-bottom: 40px;

		@include before_668 {
			flex-direction: column;
			gap: 20px;
		}

		&__anonim {
			color: #30373C;
			fill: #30373C;
			border-radius: 14px;
			background-color: #D5DFE6;
			border: 1px solid #D5DFE6;

			@include before_668 {
				text-align: left;
				justify-content: left;
				height: 76px;
			}

			svg {
				color: #30373C;
				fill: #30373C;
			}
			&:hover {
				color: white;
				background: rgb(116,221,34);
				background: linear-gradient(52deg, rgba(116,221,34,1) 0%, rgba(41,216,163,1) 100%);

				svg {
					color: white;
					fill: white;
				}
			}
			&.active {
				color: white;
				background: rgb(116,221,34);
				background: linear-gradient(52deg, rgba(116,221,34,1) 0%, rgba(41,216,163,1) 100%);

				svg {
					color: white;
					fill: white;
				}
			}
		}
		.ns-button__flex {
			gap: 10px;
		}
	}
}

.ns-tabs__item {
	.ns-button {
		min-width: 213px;
	}
}
