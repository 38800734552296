@import '../../../assets/global-style/mixin';

.ns-title {
    font-weight: 600;
    line-height: 120%;
    color: var(--t-primery-c);

    &--fs-24-400 {
        font-size: 24px;
        font-weight: 400;

        @include before_1200 {
            font-size: 20px;
        }

        @include before_480 {
            font-size: 18px;
        }
    }

	&--fs-24-600 {
        font-size: 24px;
        font-weight: 600;

        @include before_1200 {
            font-size: 20px;
        }

        @include before_480 {
            font-size: 18px;
        }
    }
	&--fs-32-600 {
        font-size: 32px;
        font-weight: 600;

		@include before_768 {
			font-size: 25px;
		}

		@include before_668 {
			font-size: 24px;
		}

    }

	&--fs-20-600 {
		font-size: 20px;
		font-weight: 600;

		@include before_768 {
			font-size: 18px;
		}

	}

    &--fs-36-400 {
        font-size: 36px;
        font-weight: 400;

        @include before_1200 {
            font-size: 32px;
        }

        @include before_480 {
            font-size: 24px;
        }
    }
	&--fs-36-500 {
        font-size: 36px;
        font-weight: 500;

        @include before_1200 {
            font-size: 32px;
        }

        @include before_480 {
            font-size: 24px;
        }
    }

	&--fs-36-600 {
        font-size: 36px;
        font-weight: 600;

        @include before_1200 {
            font-size: 32px;
        }

        @include before_480 {
            font-size: 24px;
        }
    }

	&--fs-32-600 {
        font-size: 32px;
        font-weight: 600;

        @include before_1200 {
            font-size: 24px;
        }

        @include before_480 {
            font-size: 18px;
        }
    }

	&--fs-28-400 {
        font-size: 28px;
        font-weight: 400;

        @include before_1200 {
            font-size: 22px;
        }

        @include before_480 {
            font-size: 20px;
        }
    }

    &--fs-36-700 {
        font-size: 36px;
        font-weight: 700;
		line-height: 115%;

		@include before_1200 {
			font-size: 32px;
		}

		@include before_668 {
			font-size: 24px;
		}
    }

	&--fs-40 {
        font-size: 40px;
		line-height: 115%;

		@include before_1200 {
			font-size: 32px;
		}

		@include before_668 {
			font-size: 24px;
		}
    }

    &--fs-40-600 {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -.2px;

        @include before_1200 {
            font-size: 32px;
        }

        @include before_480 {
            font-size: 24px;
            line-height: 32px;
        }
    }

	&--fs-48 {
        font-size: 48px;
		font-weight: 400;

		@include before_1200 {
			font-size: 32px;
		}

		@include before_668 {
			font-size: 24px;
		}
    }

	&--fs-48-600 {
        font-size: 48px;
		font-weight: 600;

		@include before_1200 {
			font-size: 32px;
		}

		@include before_668 {
			font-size: 24px;
		}
    }

	&--fs-80_300 {
		font-size: 80px;
		font-weight: 300;

		@include before_480 {
			font-size: 60px;
		}
	}

	&--80_700 {
		font-size: 128px;
		line-height: 128px;
		font-weight: 300;
		background: linear-gradient(6deg, rgba(21,176,21,1) 13%, rgba(4,198,201,1) 87%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

    &--half-gray2 {
        color: var(--s-secondary-c-5);
    }
	&--center {
      text-align: center;
    }
}
