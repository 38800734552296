.ns-sticker {
    display: inline-block;
    padding: 5px 24px;
    color: var(--t-inverted-c);
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    background:  var(--s-gradient-c-1);
    border-radius: 8px;

    &--gray {
        color: var(--t-primery-c);
        background: var(--s-inverted-c);
    }
}
