@import '../../../assets/global-style/mixin';
.ns-cell {
	&__p20 {
		padding: 20px;
	}
}
.ns-cell-select {
    position: relative;
    width: 100%;
    z-index: 10;


    &__header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--s-inverted-c);
        text-align: center;
		height: 17px;
		max-width: 50px;

        &--v2 {
            border: 1px solid var(--s-secondary-c-3);
            border-radius: 12px;
            background: var(--s-inverted-c);
            backdrop-filter: blur(26px);
            padding: 16px 24px;
			height: 60px;
        }

        @include before_1220 {
            height: 19px;
        }

        @include before_1200 {
            height: 17px;
        }
    }

    &__current {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: var(--s-secondary-c-1);
        // opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.32, 0, 0.67, 0);

		@include before_1220 {
			font-size: 14px;
		}

		@include before_1200 {
			font-size: 12px;
		}
    }

    &__icon {
        fill: var(--s-secondary-c-2);
        transition: 0.5s;

        margin-left: 7px;
        margin-top: 2px;

        @include before_768 {
            width: 14px;
            height: auto;
        }
    }

    &__body-icon {
        opacity: 0;
        fill: var(--s-secondary-c-2);
        transition: 0.2s;
    }

    &__body-link {
        text-decoration: none;
        transition: 0.5s;
    }

    &__body-link:hover {
        color: var(--s-accent-c);
    }

    &__body {
        display: none;
        position: absolute;
        left: 50%;
        right: 0;
        top: calc(100% + 20px);
        // width: calc(100% + 26px);
        width: 351px;
        max-height: 300px;
        overflow-y: auto;
        transform: translate(-50%, 0);
        background-color:  var(--s-inverted-c);
        box-shadow: 0px 2px 15px 0px var(--s-secondary-c-2);
        border-radius: 16px;

        &--columns2 {
            width: 681px;
            max-height: initial;
            grid-template-columns: repeat(2, 1fr);
            gap: 0 10px;
        }
    }

    &.is-active {
        z-index: 11;

        .cell-select__icon {
            transform: rotate(180deg);
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        padding: 16px 24px;
        color: var(--s-secondary-c-2);
        transition: color 0.5s;

		&-info {
			width: 100%;
			display: block;
		}

        a {
            border-bottom: none;
			width: 100%;
			display: block;
        }

        @include before_768 {
            font-size: 14px;
        }

        // &:hover {
        //     background-color: #f2f2f2;
        // }

        &.is-chosen {
            color: var(--s-secondary-c-1);

            .cell-select__body-icon {
                opacity: 1;
            }
        }
    }

    &__item:not(:last-child) {
        border-bottom: 0.2px solid rgba(231, 232, 239, 0.66);
    }
}
