@import '../../../assets/global-style/mixin';

.ns-main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0; //Фиксим swiper slider
	position: relative;
}
.main {
    //padding-bottom: 104px;

    &__title {
        margin-bottom: 40px;

        @include before_768 {
            margin-bottom: 24px;
        }
    }

    &__ns-text {
        margin-bottom: 24px;
    }
}
