@import '../../../assets/global-style/mixin';

.ns-cell {
    &--mb24 {
        margin-bottom: 24px;
    }

    &__name {
        position: relative;
        display: block;
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 18px;
        color: var(--text-primery-color);
        background-color: transparent;
        transition: 0.5s;

        @include before_768 {
            font-size: 14px;
        }
    }

    &__content {
        height: 100%;
        position: relative;
		padding: 12px 24px;
		border: 1px solid var(--text-primery-color);
		background-color: transparent;
        border-radius: 16px;
        transition: 0.5s;

        &--search {
            border: none;
            border-bottom: 1px solid var(--s-secondary-c-7);
            border-radius: 0;
            padding: 5px 0;
        }

        &--menu {
            border: none;
            backdrop-filter: blur(26px);
            border: 1px solid var(--s-inverted-c);
            background: var(--s-secondary-c-4);
            box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
            border-radius: 24px;
            padding: 3px 24px;
        }

        &--selector {
            display: flex;
            border: none;
            border-radius: 0;
            padding: 0;
        }

        .ns-cell__data {
            @include before_1220 {
                display: flex;
                align-items: end;
            }
        }
    }

	&__content-1 {
        height: 100%;
        position: relative;

        transition: 0.5s;

		.ns-cell__data {
			padding: 17px 18px;
			backdrop-filter: blur(26px);
			border: 1px solid #8a959d5c;
			background: var(--s-secondary-c-4);
			box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
			border-radius: 14px;
		}

        &--search {
            border: none;
            border-bottom: 1px solid var(--s-secondary-c-7);
            border-radius: 0;
            padding: 5px 0;
        }

        &--menu {
            backdrop-filter: blur(26px);
            border: 1px solid var(--s-inverted-c);
            background: var(--s-secondary-c-4);
            box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
            border-radius: 24px;
            padding: 3px 24px;
        }

        &--selector {
            display: flex;
            border: none;
            border-radius: 0;
            padding: 0;
        }
    }

	&__content-2 {
        height: 100%;
        position: relative;
        padding: 17px 18px;
		background: white;
        //border: 1px solid var(--text-primery-color);
        border-radius: 16px;
        transition: 0.5s;

        &--search {
            border: none;
            border-bottom: 1px solid var(--s-secondary-c-7);
            border-radius: 0;
            padding: 5px 0;
        }

        &--menu {
            border: none;
            backdrop-filter: blur(26px);
            border: 1px solid var(--s-inverted-c);
            background: var(--s-secondary-c-4);
            box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
            border-radius: 24px;
            padding: 3px 24px;
        }

        &--selector {
            display: flex;
            border: none;
            border-radius: 0;
            padding: 0;
        }
    }

    &__data {
        &--search {
            display: flex;
            gap: 24px;
        }

        &--news {
            display: flex;
            justify-content: space-between;
            gap: 24px;
        }

        &--menu {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 24px;
        }

        &.w100 {
            width: 100%;
        }
    }

    &__error-message {
        margin-top: 6px;
        font-size: 16px;
        line-height: 140%;
        color: var(--error-primery-color);
        display: none;
    }

    &__identificator {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translate(0, -50%);
    }

    &__icon {
        display: block;
    }

    &.js-focus {
        .cell__content {
            border-color: var(--s-secondary-c-6);
        }
    }

    &.js-cell-error {
        .cell__content {
			color: var(--error-primery-color);
        }
    }

    &.js-cell-disabled {
        .cell__content {
            background-color: var(--calm-color-1);
            pointer-events: none;
            cursor: not-allowed;
        }
    }

    &.js-cell-hidden {
        display: none;
    }

    &--subscription {
        flex-grow: 1;
        margin-right: 16px;

        .ns-cell__name {
            margin-bottom: 0;
        }
        .ns-cell__content {
            padding: 16px 24px;
            border-color: var(--s-secondary-c-2);
            background-color: var(--s-inverted-c);
        }
    }

    &__flex {
        display: flex;
        gap: 24px;
        width: 100%;
    }
}
