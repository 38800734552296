.ns-footer {
	&__el {
		display: flex;
		flex-direction: column;
		gap: 12px;
	}
    &__grid-wrapper {
		display: flex;
		flex-direction: row;
        gap: 64px;
		justify-content: space-between;

        @include before_550 {
			flex-direction: column;
			gap: 16px;
        }

        &--col {
            display: flex;
            flex-direction: column;
            gap: 61px;
			order: 1;

            @include before_480 {
                gap: 40px;
            }

			&--button {
				justify-content: flex-end;
				flex-direction: row;
				@include before_1024 {
					justify-content: center;
				}
				#secondSpecialButton {
					background: transparent;
					color: var(--t-primery-c);
					border: 1px solid var(--s-secondary-c-1);
					box-shadow: none;
					width: 23%;
                    max-width: 277px;
                    padding: 16px;
					margin-top: -60px;

					@include before_1024 {
						width: 100%;
						margin-top: 24px;
                        max-width: initial;
					}

					&:hover {
						background: var(--s-secondary-c-1);
						color: var(--t-inverted-c);
						border: 1px solid var(--s-inverted-c);
					}
				}
			}

			&--desktop {
				display: flex;
				@include before_1024 {
					display: none;
				}
			}
			&--mobile {
				display: none;
				@include before_1024 {
					display: flex;
				}
			}

        }

        &--mw {
            min-width: 277px;
        }

        &--col2 {
            grid-template-columns: repeat(2, 1fr);
            gap: 99px;
        }

		&--el {
			display: flex;
			flex-direction: column;
			gap: 12px;
			&--desktop {
				display: flex;
				@include before_550 {
					display: none;
				}
			}
			&--mobile {
				display: none;
				@include before_550 {
					display: flex;
				}
			}
		}
    }

    &__content {
        &--top {
            background: var(--s-secondary-c-4);
            padding: 64px 0;
        }
        &--bottom {
            background: var(--s-secondary-c-8);
            padding: 24px 0 80px 0;
        }
    }

    &__flex-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
		@include before_550 {
			gap: 40px;
		}
    }

    &__flex-wrapper {
        display: flex;
		flex-direction: column;
        justify-content: space-between;
        gap: 61px;

        @include before_768 {
            flex-direction: column;
        }

        @include before_480 {
            gap: 32px;
        }

        &--my16 {
            margin: 16px 0;
        }
    }

    &__notions {
        display: flex;
        flex-direction: column;
        gap: 12px;

        width: 65%;

        @include before_768 {
            width: 100%;
        }
    }

    &__agreements {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__link {
        text-decoration: none;
        border-bottom: none;
        transition: none;

        &--underline {
            border-bottom: none;
            transition: none;
            text-decoration: underline;
            width: fit-content;
        }

        &--underline:hover {
            color: var(--t-descriptive-c-2);
            text-decoration: none;
            border-bottom: none;
            transition: none;
        }
    }

    &__link:hover {
        color: var(--t-descriptive-c-2);
        text-decoration: none;
        border-bottom: none;
        transition: none;
    }

    &__button {
        margin-top: 32px;
    }
}
