@import '../../../assets/global-style/mixin';
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--s-inverted-c);
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 50;

    &.is-active {
        opacity: 1;
        visibility: visible;

        .popup__content {
            transform: translate(0, 0);
            opacity: 1;
        }
    }

    &__container {
        display: flex;
        // align-items: center;
        justify-content: center;
        // min-height: 100%;
        padding: 30px 10px;
    }

    &__content {
        position: relative;
        width: 100%;
        padding: 40px 48px 48px;
        background-color:  var(--s-inverted-c);
        transition: 0.5s;
        transform: translate(0, -100%);
        opacity: 0;

        @include before_768 {
            padding: 20px 24px 24px;
        }
    }

    &__close {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;

        &-icon {
            fill: var(--icon-primery-color);
            transition: 0.5s;

            &:hover {
                fill: var(--t-primery-c);
            }
        }
    }

    // &__result {
    //     display: none;

    //     &.active {
    //         display: block;
    //     }
    // }

    &__result ul {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    &__result a {
        color: var(--s-accent-c);
        text-decoration: none;
    }

    &__result h6 {
        font-size: 16px;
    }

    &__result a:hover {
        border-bottom: 1px solid var(--s-brand-c);
    }

    &--news {
        background-color: var(--s-secondary-c-1);

        &__container {
            height: 100%;
        }

        &__content {
            width: 50%;
            height: 608px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            border-radius: 6px;
            background: var(--s-inverted-c);
            padding: 80px 80px 60px;
            overflow: hidden;
        }

        &__content-wrapper {
            height: 100%;
            overflow-y: auto;
        }

        &__close {
            position: absolute;
            right: 16px;
            top: 16px;
            cursor: pointer;
        }

        &__date {
            margin-bottom: 8px;
        }

        &__title {
            margin-bottom: 40px;
        }

        &__inner-content {
            display: flex;
            flex-direction: column;
            gap: 24px;

            margin-bottom: 32px;
        }
    }
}
