@import '../../../assets/global-style/mixin';

.ns-tabs {
    display: flex;
    flex-direction: column;

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        @include before_1024 {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__list {
        display: flex;
        align-self: flex-start;
        gap: 20px;
        margin-bottom: 12px;
		padding-top: 20px;
		padding-bottom: 28px;
		padding-right: 20px;
		padding-left: 20px;
		overflow-x: auto;
		left: -20px;
		position: relative;
		@include before_768 {
			width: calc(100% + 35px);
		}
    }

    &__name {
        padding: 11px 16px;
        font-size: 16px;
        line-height: 140%;
        flex-shrink: 0;
        color: var(--t-primery-c);
        box-shadow: 6px 17px 24px rgba(73, 75, 89, 0.04);
        background: var(--s-secondary-c-4);
        backdrop-filter: blur(26px);
        border-radius: 24px;
        cursor: pointer;
        transition: 0.5s;

        &.active {
            color: var(--t-inverted-c);
            background: var(--s-gradient-c-1);
            box-shadow: 0px 8px 16px rgba(36, 201, 33, 0.16);
        }

        // &:hover {
        //     color: var(--color-white);
        // }
    }

    &__right {
        @include before_1024 {
            margin-top: 12px;
        }
    }
	&--footer {
		margin-top: 32px;
	}
}
