@import '../../../assets/global-style/mixin';

.line-tabs {
    display: flex;
    flex-direction: column;

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        overflow-x: auto;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__list {
        display: flex;
        align-self: flex-start;
        gap: 20px;
        overflow-x: auto;
        margin-bottom: 80px;

        @include before_550 {
            width: 100%;
        }

        @include before_480 {
            margin-bottom: 48px;
        }
    }

    &__name {
        padding: 14px 6px 0 6px;
        font-size: 16px;
        line-height: 100%;
        flex-shrink: 0;
        color: var(--t-primery-c);
        cursor: pointer;
        transition: 0.5s;

        &.active {
            .green-line {
                border-radius: 15px 15px 0px 0px;
                border: 3px solid var(--color-green2);
                margin-top: 19px;
            }
        }

        // &:hover {
        //     color: var(--color-white);
        // }
    }

    &__right {
        @media screen and (max-width: 1023px) {
            margin-top: 12px;
        }
    }
    &--footer {
        margin-top: 32px;
    }
    .gray-line {
        border-bottom: 1px solid var(--color-black);
        opacity: 0.4000000059604645;
        width: 100%;
        margin-bottom: 23px;
    }
}
