@import '../../../assets/global-style/mixin';

.ns-button-text {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: var(--t-accent-c);
    text-decoration: none;

    background-color: transparent;
    border: none;

    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
    -webkit-appearance: none; //убирает дефолтные стили в ios

    width: fit-content;

    &--flex {
        display: flex;
    }

    &__flex {
        display: flex;
        align-items: center;

        &--column {
            flex-direction: column;
            gap: 12px;
        }
    }

    &__el {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    &__icon {
        fill: var(--t-descriptive-c-4);
        transition: 0.5s;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        opacity: 0.5;
        transition: 0.1s;
    }

    &:focus {
        opacity: 0.9;
        transition: 0.1s;
    }

    &.disabled {
        opacity: 0.8;
        pointer-events: none;
    }

    &--search {
        font-size: 20px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color:  var(--t-primery-c);
    }
}
