@import '../../../assets/global-style/mixin';

.ns-dropdown {
    &__item {
        backdrop-filter: blur(26px);
        border: 1px solid var(--s-inverted-c);
        background: var(--s-secondary-c-4);
        box-shadow: 4px 15px 24px -8px rgba(73, 75, 89, 0.04);
        border-radius: 12px;
        padding: 16px 24px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }

        &.active {
            .js-dropdown__rotate {
                transform: rotate(180deg);
            }
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.6;
        }

        &--vacancy {
            padding: 16px 24px;
            background: var(--s-secondary-c-4);
            border-radius: 24px;
        }
    }

    &__title:hover &__text {
		color: var(--t-accent-c-2);
	}
	&__title:hover  {

		.ns-dropdown__icon {
			fill: var(--t-accent-c-2);
		}
	}

    &__text {
        transition: 0.5s;
    }

    &__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        gap: 32px;
        @include before_550 {
            gap: 12px;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        // gap: 32px;
        // @include before_550 {
        // 	gap: 12px;
        // }
    }

    &__number {
        margin-right: 6px;
    }

    &__icon {
        margin-right: 12px;
    }

    &__content {
        display: none;
        padding-top: 16px;

        &--vacancy {
            display: none;
            margin: 44px 0 40px;
        }
    }

    &__card-title {
        flex-grow: 1;
    }

    &__inner-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
        padding: 12px 0 24px 63px;
    }

    &__inner-list:not(:last-child) {
        margin-bottom: 10px;
    }

    &__link {
        text-decoration: none;
        border-bottom: none;
    }

    .js-dropdown__rotate {
        transition: 0.5s;
    }
}
