.ns-menu {
    &__button {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 26px;
        height: 26px;
        cursor: pointer;
        z-index: 1;
    }

    &__button > span,
    &__button > span::before,
    &__button > span::after {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: var(--s-secondary-c-2);
        transition-duration: 0.25s;
    }
    &__button > span::before {
        content: '';
        top: -8px;
    }
    &__button > span::after {
        content: '';
        top: 8px;
    }

    &__content {
        display: block;
        position: fixed;
        visibility: hidden;
        top: 65px;
        right: -100%;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 40px 20px 85px 20px;
        list-style: none;
        background: var(--s-secondary-c-8);
        transition-duration: 0.25s;
        z-index: 40;
    }

    #menu__toggle {
        opacity: 0;
    }

    #menu__toggle:checked ~ &__button > span {
        transform: rotate(45deg);
    }
    #menu__toggle:checked ~ &__button > span::before {
        top: 0;
        transform: rotate(0);
    }
    #menu__toggle:checked ~ &__button > span::after {
        top: 0;
        transform: rotate(90deg);
    }
    #menu__toggle:checked ~ &__content {
        visibility: visible;
        overflow-y: scroll;
		overflow-x: hidden;
        right: 0;
    }

    &__tabs-list {
        align-self: unset;
        overflow-x: scroll;
        padding-bottom: 36px;
        margin-bottom: 10px;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
