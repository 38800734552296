@import '../../../assets/global-style/mixin';

.ns-text {
    font-weight: 400;
    line-height: 120%;
    color: var(--t-primery-c);

	@include before_668 {
		line-height: normal;
	}

	&--center {
		text-align: center;
	}

	&--no-decoration {
		text-decoration: none;
	}

    &--fs-12-300 {
        font-size: 12px;
        font-weight: 300;
		line-height: 125%;
    }

    &--fs-16-300 {
        font-size: 16px;
        font-weight: 300;
		line-height: 140%;
		text-decoration: none;
    }

	&--fs-14-300 {
        font-size: 14px;
        font-weight: 300;
		text-decoration: none;

	}&--fs-14-600 {
        font-size: 14px;
        font-weight: 600;
	    line-height: 130%;
    }

    &--fs-20-300 {
        font-size: 20px;
        font-weight: 300;
		@include before_1024 {
			font-size: 18px;
		}
        @include before_480 {
            font-size: 16px;
        }
    }

    &--fs-12 {
        font-size: 12px;
		line-height: 130%;
    }

	&--fs-10 {
        font-size: 10px;
		line-height: 140%;
    }

    &--fs-14 {
        font-size: 14px;
		line-height: 130%;
    }

    &--fs-15 {
        font-size: 15px;
    }

    &--fs-16 {
		line-height: 140%;
        font-size: 16px;

		@include before_1220 {
			font-size: 14px;
		}

		@include before_1200 {
			font-size: 12px;
		}
    }

    &--fs-20 {
        font-size: 20px;

		@include before_1024 {
			font-size: 20px;
		}
		@include before_668 {
			font-size: 18px;
		}
    }

    &--fs-24 {
        font-size: 24px;

		@include before_1200 {
			font-size: 20px;
		}

		@include before_480 {
			font-size: 18px;
		}
    }

	&--fs-24_400 {
        font-size: 24px;
		font-weight: 400;

		@include before_1200 {
			font-size: 20px;
		}

		@include before_480 {
			font-size: 18px;
		}
    }

	&--fs-34 {
        font-size: 24px;
    }

	&--fs-18 {
		font-size: 18px;
		line-height: 140%;
		@include before_668 {
			font-size: 16px;
		}
	}

	&--fs-19 {
		font-size: 19px;
		@include before_768 {
			font-size: 16px;
		}
	}

    &--fs-16-600 {
        font-size: 16px;
        font-weight: 600;
		line-height: 130%;
    }

    &--fs-18-600 {
        font-size: 18px;
		line-height: 120%;
        font-weight: 600;
    }

	&--fs-20-600 {
        font-size: 20px;
        font-weight: 600;
		//@include before_1024 {
		//	font-size: 18px;
		//}
		@include before_668 {
			font-size: 18px;
		}
    }

	&--fs-20-700 {
        font-size: 20px;
        font-weight: 700;
		line-height: 130%;
		@include before_1024 {
			font-size: 18px;
		}
		@include before_768 {
			font-size: 14px;
		}
    }

    &--fs-24-600 {
        font-size: 24px;
        font-weight: 600;
		@include before_1200 {
			font-size: 20px;
		}

		@include before_480 {
			font-size: 18px;
		}

    }

	&--fs-32-600 {
		font-size: 32px;
		font-weight: 600;

		@include before_768 {
			font-size: 20px;
		}

		@include before_668 {
			font-size: 20px;
		}

	}

	&--fs-48 {
		font-size: 48px;
	}

	&--gray {
        color: var(--t-descriptive-c-3);

        a {
            color: var(--t-descriptive-c-3);
        }

        a:hover {
            color: var(--t-descriptive-c-3);
        }
    }

    &--gray4 {
        color: var(--t-descriptive-c-3);
    }

	&--normal {
		color: var(--t-primery-c);
    }

    &--half-gray2 {
        color: var(--s-secondary-c-5);
    }

    &--green {
        color: var(--t-accent-c-2);
    }
}

sup {
	font-size: 10px;
	line-height: 10px;
}
